<template>
  <div>
    <div class="banner">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in portfolioData.banners" :key="index">
          <img width="100%" :src="item.bgUrl" />
        </swiper-slide>
      </swiper>
      <div class="portfolio-lists">
        <div
          :class="{
            'portfolio-lists-item-active': selectKey === item.key,
            'portfolio-lists-item': true,
          }"
          v-for="item in navs"
          :key="item.key"
          v-on:click="onClick(item.key)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="container">
      <div class="grid-box">
        <div
          class="grid-item"
          v-for="(item, index) in lists"
          :style="activeStyles[index]"
          :key="index"
        >
          <div class="grid-center" @mouseover="hoverIndex = index" @mouseout="hoverIndex = null">
            <img 
              :class="
                ['hover-rubberBand','hover-jello', 'hover-shakeX','hover-pulse']
                [Math.floor((Math.random()*['hover-rubberBand','hover-jello','hover-shakeX','hover-pulse'].length))]
              " 
             @click="onToOutSideClick(item)" 
             :src="hoverIndex === index && item.url.eUrl ? item.url.eUrl : item.imgUrl" />
            <!-- <p class="name">{{ $store.state.lang=='zh'? item.nameLangZh: item.name }}</p> -->
          </div>
        </div>
      </div>
      <div class="grid-box grid-box-hidden">
        <div
          class="grid-item grid-item-hidden"
          v-for="(item, index) in currentLists"
          :key="index"
        >
          <div class="grid-center" @click="onToOutSideClick(item.url)">
            <img class="hover-pulse" :src="item.imgUrl" />
            <!-- <p class="name">{{ $store.state.lang=='zh'? item.nameLangZh: item.name }}</p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="popBox" @click.stop="showPop = false" v-if="showPop">
      <div class="popMain" @click.stop>
        <div class="left-text">
          <!-- <div class="title">{{newContent.url.title}}</div> -->
          <div class="content">{{$store.state.lang=='zh'?newContent.nameLangZh: newContent.name}}</div>
          <span class="moreBtn" @click.stop="onToOutSideClick(null, 'url')">MORE</span>
        </div>
        <div class="right-logo">
          <img style="object-fit: cover;" :src="newLogo" alt="">
        </div>
        <div class="closeBtn" @click.stop="showPop = false">&times;</div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import {getPortfolioContent} from "@/apis"
// {"url":"/","rUrl":"https://cvp-new-version.oss-accelerate.aliyuncs.com/pcpic/15-1.png"}
// ,"eUrl": "https://cvp-new-version.oss-accelerate.aliyuncs.com/pcpic/15-2.png"
export default {
  name: "Portfolio",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      hoverIndex: null,
      showPop: false,
      toOutSideUrl: '',
      newLogo:'',
      newContent:{},
      lists: [
        // {
        //   name: "LEADING AI COMPANY FOR RETAIL DIGITALIZATION",
        //   imgUrl: require("@/assets/allImage/logo1.png"),
        //   key: "digitals",
        // },
        // {
        //   name: "LEADING AUTONOMOUS DRIVING COMNPANY",
        //   imgUrl: require("@/assets/allImage/logo2.png"),
        //   key: "lifestyles",
        // },
        // {
        //   name: "LEADING O2O AFTER SCHOOL TUTORING PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo3.png"),
        //   key: "healths",
        // },
        // {
        //   name: "MOBILE CROWD-TASKING & MARKETING PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo4.png"),
        //   key: "public",
        // },
        // {
        //   name: "GLOBAL CROSS BORDER E-COMMERCE PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo5-1.png"),
        //   key: "digitals",
        // },
        // {
        //   name: "LEADING AUTONOMOUS DRIVING COMNPANY",
        //   imgUrl: require("@/assets/allImage/logo6.png"),
        //   key: "lifestyles",
        // },
        // {
        //   name: "LEADING AUTONOMOUS DRIVING COMNPANY",
        //   imgUrl: require("@/assets/allImage/logo17.png"),
        //   key: "healths",
        // },
        // {
        //   name: "LEADING AI COMPANY FOR RETAIL DIGITALIZATION",
        //   imgUrl: require("@/assets/allImage/logo8-1.png"),
        //   key: "public",
        // },

        // {
        //   name: "LEADING O2O AFTER SCHOOL TUTORING PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo1.png"),
        //   key: "digitals",
        // },
        // {
        //   name: "GLOBAL CROSS BORDER E-COMMERCE PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo2.png"),
        //   key: "lifestyles",
        // },
        // {
        //   name: "LEADING AI COMPANY FOR RETAIL DIGITALIZATION",
        //   imgUrl: require("@/assets/allImage/logo3.png"),
        //   key: "healths",
        // },
        // {
        //   name: "LEADING O2O AFTER SCHOOL TUTORING PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo4.png"),
        //   key: "public",
        // },
        // {
        //   name: "MOBILE CROWD-TASKING & MARKETING PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo5-1.png"),
        //   key: "digitals",
        // },
        // {
        //   name: "GLOBAL CROSS BORDER E-COMMERCE PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo6.png"),
        //   key: "lifestyles",
        // },
        // {
        //   name: "MOBILE CROWD-TASKING & MARKETING PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo15.png"),
        //   key: "healths",
        // },
        // {
        //   name: "LEADING AI COMPANY FOR RETAIL DIGITALIZATION",
        //   imgUrl: require("@/assets/allImage/logo17.png"),
        //   key: "public",
        // },
        // {
        //   name: "LEADING O2O AFTER SCHOOL TUTORING PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo17.png"),
        //   key: "digitals",
        // },
        // {
        //   name: "GLOBAL CROSS BORDER E-COMMERCE PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo18.png"),
        //   key: "lifestyles",
        // },
        // {
        //   name: "MOBILE CROWD-TASKING & MARKETING PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo1.png"),
        //   key: "healths",
        // },

        // {
        //   name: "LEADING AUTONOMOUS DRIVING COMNPANY",
        //   imgUrl: require("@/assets/allImage/logo20.png"),
        //   key: "public",
        // },
        // {
        //   name: "MOBILE CROWD-TASKING & MARKETING PLATFORM",
        //   imgUrl: require("@/assets/allImage/logo2.png"),
        //   key: "healths",
        // },
      ],
      navs: [
        { name: "All PORTFOLIO", key: "all" },
        { name: "PUBLIC & EXIT", key: 4 },
        // { name: "TECHNOLOGY", key: 2 },
        // // { name: "HEALTH & WELLNESS", key: 3 },
        // { name: "PUBLIC & EXIT", key: 4 },
      ],
      swiperOption: {},
      selectKey: "all",
      activeStyles: [],
      currentLists: [],
    };
  },
  computed:{
    portfolioData(){
      const portfolio = this.$store.state.mainContent.portfolio
      const lang = this.$store.state.lang
      let obj = {}
      if(portfolio && portfolio.extend){
       
        obj = {
          ...portfolio,
          ...JSON.parse(portfolio.extend)
        }

      }
      return obj
    }
  },
  mounted() {
    this.getPortfolioContent()

    
    
  },
  methods: {
    onToOutSideClick(item, type){
      if(type === 'url'){
        window.open(this.toOutSideUrl, '_blank');
        return
      }
      this.showPop = true
      this.toOutSideUrl = item.url.url
      console.log(item);
      this.newContent = item
      this.newLogo = item.url.eUrl?item.url.eUrl:item.url.rUrl
      return
      window.open(url, '_blank');

      // window.location.href = url
    },
    async getPortfolioContent(){
      const that = this;
      const res = await getPortfolioContent()
      res.data.sort( (a, b) => {
        return b.sort - a.sort
      }).map( (item) => {
        
        try {
          if(item.url && JSON.parse(item.url)){
            console.log(JSON.parse(item.url));
            item.url = JSON.parse(item.url)
          }
        } catch (error) {
          item.url = {url: "", rUrl: ""}
        }
        
        that.lists.push({
          ...item,
          // name: "LEADING AI COMPANY FOR RETAIL DIGITALIZATION",
          // imgUrl: require("@/assets/allImage/logo1.png"),
          key: item.typeid,
        },)
      })

      this.activeStyles = this.getPositon();
      this.getCurrentLists();
    },
    getPositon: function () {
      this.getCurrentLists();
      const point = 4;
      let idx = -1;
      const arr = this.lists.map((item) => {
        const isShow = this.selectKey === item.key || this.selectKey === "all";

        if (isShow) idx += 1;
        const x = `${(idx % point) * 100}%`;
        const y = `${Math.floor(idx / point) * 100}%`;
        const scale = isShow ? 1 : 0.001;
        return {
          opacity: isShow ? 1 : 0,
          width: `${(1 / point) * 100}%`,
          transform: `translate3d(${x},${y},0) scale3d(${scale}, ${scale}, ${scale})`,
        };
      });
      return arr;
    },
    onClick: function (key) {
      
      this.selectKey = key;
      this.activeStyles = this.getPositon(key);
    },
    getCurrentLists: function () {
      const arr = this.lists.filter(
        (v) => v.key === this.selectKey || this.selectKey === "all"
      );
      this.currentLists = arr;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../assets/custom.scss");

.banner {
  padding-top: 106px;
  position: relative;
  white-space: normal
}

.portfolio-lists {
  display: flex;
  justify-content: space-between;
  width: 400px;
  // position: absolute;
  margin: 30px auto 0;
  left: 5%;
  right: 5%;
  bottom: 0;
  z-index: 2;
  height: 40px;

  &-item:hover {
    background: #0d3576;
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }

  &-item {
    opacity: 1;
    background: linear-gradient(180deg, #f5f5f5 0%, #ffffff 100%);
    box-shadow: 0px 0px 4px 0px rgba(9, 1, 4, 0.29);
    font-family: Arial;
    font-weight: 200;
    color: #7d7d7d;
    line-height: 30px;
    letter-spacing: 1px;
    font-size: 16px;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    border-radius: 40px;
    cursor: pointer;
    flex: 1;
    text-align: center;
    vertical-align: middle;
    // margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    overflow: hidden;
    &:first-child {
      flex: 1.5;
      margin-right: 40px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &-item-active {
    background: #bbb;
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }
}

.grid-box {
  position: relative;
  width: 100%;
  transition-duration: 0.8s;
  margin: 20px 0;

  .grid-item {
    transition-property: transform, opacity;
    transition-duration: 0.8s;
    color: #000;
    cursor: pointer;
    position: absolute;
    overflow: hidden;
    padding: 5px;
    
    z-index: 9;
  }

  .grid-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 0px 4px 7px 0px rgba(10, 2, 4, 0.28);
    img {
      width: 100%;
    }
  }

  .name {
    // font-family: Arial;
    border-top: 1px solid #000000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }
}

.grid-box-hidden {
  display: flex;
  flex-wrap: wrap;
  .grid-item-hidden {
    position: relative;
    width: 25%;
    visibility: hidden;
  }
}

.popBox{
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  z-index: 99999;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
  .popMain{
    background-color: #fff;
    max-width: 1200px;
    width: 50%;
    height: 50%;
    max-height: 500px;
    display: flex;
    position: relative;
    .closeBtn{
      position: absolute;
      font-size: 40px;
      top: 10px;
      cursor: pointer;
      right: 20px;
    }
    .left-text{
      width: 60%;
      height: 100%;
      box-sizing: border-box;
      padding: 40px 10px 40px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 18px;
      white-space: none;
      color: #333;
      .title{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .moreBtn{
        margin-top: 20px;
        width: 60px;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        border: 1px solid #AAA;
        // display: inline-block;
        
        padding: 6px 0;
        &:hover{
          background-color: #aaa;
          color: #fff;
        }
      }
    }
    .right-logo{
      overflow: hidden;
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 10px;
      box-sizing: border-box;
      img{
        // width: 140%;
        // height: 140%;
      }
    }
  }
  
}
</style>